import { useEffect } from 'react';
import Image from '@components/images/RentennialsNextImage';
import Loading from '@components/layout/Loading';
import { ACCESS_TOKEN_COOKIE_KEY, DEFAULT_LOCALE } from '@constants/index';
import { useAuthContext } from '@utils/hooks/useAuthContext';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { z } from 'zod';

import RentennialsLogo from '../../public/images/Logo_Rentennials_Bottom.svg';

const LoginContent = dynamic(() => import('@components/login/LoginContent'), { ssr: false });

const LoginSchema = z.object({ callback: z.string().optional() });

export const getServerSideProps = (async ({ req, locale, defaultLocale, query }) => {
  const accessToken = req.cookies[ACCESS_TOKEN_COOKIE_KEY];

  const { callback } = LoginSchema.parse(query);

  if (accessToken) {
    return {
      redirect: {
        destination: callback || '/',
        permanent: false
      }
    };
  }

  const i18n = await serverSideTranslations(locale || defaultLocale || DEFAULT_LOCALE, ['common']);

  return {
    props: {
      ...i18n,
      locale: locale || defaultLocale || DEFAULT_LOCALE,
      callback: callback || null
    }
  };
}) satisfies GetServerSideProps;

const Login = ({ callback }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { onUserAuthenticated, isUserAuthenticated } = useAuthContext();

  const handleLoginComplete = async (key: string) => {
    await onUserAuthenticated(key);
    location.href = callback || '/';
  };
  useEffect(() => {
    if (isUserAuthenticated) {
      location.href = callback || '/';
    }
  }, [callback, isUserAuthenticated]);

  return (
    <div
      className="flex h-screen w-screen items-center justify-center bg-cover bg-no-repeat px-2"
      style={{ backgroundImage: "url('/images/Back_Login.svg')" }}
    >
      {isUserAuthenticated ? (
        <Loading />
      ) : (
        <div className="max-w-[400px] rounded-2xl border-[1px] border-gray-200/50 bg-white p-2 shadow-lg min-[370px]:p-10">
          <Link href="/">
            <div className="flex items-center justify-center pb-2 pt-6">
              <Image priority alt="Rentennials" src={RentennialsLogo} height={44} width={229} />
            </div>
          </Link>
          <LoginContent onLoginComplete={handleLoginComplete} />
        </div>
      )}
    </div>
  );
};

export default Login;
